var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-7",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Deka Prime on Premise Usage ")])]),_c('v-col',{attrs:{"md":"1"}}),(_vm.role.toLowerCase() == 'superadmin')?_c('v-col',{attrs:{"md":"2"}},[(!_vm.read_only)?_c('router-link',{attrs:{"to":"/dekapop/create"}},[_c('v-btn',{staticClass:"fz-14",attrs:{"block":"","color":"secondary","dark":"","height":"45","width":"150"}},[_vm._v("Create Deka POP")])],1):_vm._e()],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"mb-5",attrs:{"items":_vm.getListPop,"headers":_vm.headersPop,"item-key":"id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","hide-default-footer":"","server-items-length":_vm.totalRowsPop},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"bs-no pa-4"},[(!item.license_name)?_c('v-col',{staticClass:"d-flex justify-center"},[_vm._v(" no data available")]):_vm._e(),(item.license_name)?_c('v-col',[_c('h2',[_vm._v("Usage")]),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"mb-1 font-weight-regular"},[_vm._v(" Usage Hour ")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(item.usage_hour))])]),_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"mb-1 font-weight-regular"},[_vm._v(" Total Core ")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(item.total_cores))])])],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"mb-1 font-weight-regular"},[_vm._v(" Commitment Core ")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(item.commitment_core))])]),_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"mb-1 font-weight-regular"},[_vm._v(" On Demand Core ")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(item.on_demand_core))])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"mb-1 font-weight-regular"},[_vm._v(" Month / Year ")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(item.month)+" / "+_vm._s(item.year))])])],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}}),_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}})],1)],1)],1)],1):_vm._e()],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                            on.click($event);
                            _vm.show($event, item);
                          }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                            rotate: item.id == _vm.activemenuinstance && _vm.popo,
                          },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',{staticClass:"cursor-pointer font-weight-bold"},[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_c('router-link',{attrs:{"to":("dekapop/details/" + (item.id))}},[_vm._v("Details")])],1)])],1),(_vm.role.toLowerCase() == 'superadmin')?_c('v-list-item',[(!_vm.read_only)?_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_c('router-link',{attrs:{"to":("dekapop/edit/" + (item.id))}},[_vm._v("Edit")])],1)]):_vm._e()],1):_vm._e(),(_vm.role.toLowerCase() == 'superadmin')?_c('v-list-item',[(!_vm.read_only)?_c('v-list-item-title',[_c('span',{staticClass:"red--text",on:{"click":function () {
                                _vm.dataItem = item;
                                _vm.openDialog = true;
                              }}},[_vm._v("Delete")])]):_vm._e()],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.changePage(val);
                      }}})]}}],null,true)})],1)],1)],1)],1)],1)],1),(_vm.openDialog)?_c('DeletePop',{attrs:{"dialog-closed":_vm.openDialog,"data-item":_vm.dataItem},on:{"update:dialogClosed":function($event){_vm.openDialog=$event},"update:dialog-closed":function($event){_vm.openDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }